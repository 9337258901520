<template>
    <div>
        <van-popup v-model="show" position="top" :overlay="false" :style="{ background: background }">
            <van-nav-bar placeholder fixed left-arrow :border="false" z-index="99" class="header-reading"
                @click-left="returnClick">
                <template #title>
                    <span :class="background == Color ? 'active' : ''">{{
            readList.title
          }}</span>
                </template>
                <template #left>
                    <van-icon name="arrow-left" size="25" color="#fff" v-if="background == Color" />
                    <van-icon name="arrow-left" size="25" color="#0A1C33" v-else />
                </template>
            </van-nav-bar>
        </van-popup>

        <!--        文章显示-->
        <div class="reading" :style="{ background: background }" ref="scroll">
            <!-- <div
        @click="readingClick"
        :class="background == Color ? 'active' : ''"
        :style="{
          'font-size': fontSize + 'px',
          'line-height': lineHeight + 'px',
        }"
        v-html="readList.info"
        
      ></div> -->

            <v-touch @swipeleft="touchswipeleft" @swiperight="touchswiperight" @tap="touchswipertap"
                :class="background == Color ? 'active' : ''" :style="{
          'font-size': fontSize + 'px',
          'line-height': lineHeight + 'px',
        }">
                <!-- <v-touch
         @click="readingClick"
          @swipeleft="touchswipeleft"
          @swiperight="touchswiperight"
          @tap="touchtap"
        ></v-touch> -->

                <div class="read-content" v-if="isshowpage === 1">
                    <p class="book-title" :style="{ fontSize: '10px', style: 'padding: 10px' }">
                        {{ readList.title }}
                    </p>
                    <div class="read-article">
                        <section class="read-section" :style="styleObject">
                            <div class="read-section-inner" :style="{ columns: clWidth  + 'px', columnGap: 0 + 'px' }"
                                ref="bookInner" id="bookInner">
                                <h1 class=".book-maintitle" :style="{ fontSize: fontSize * 1.2 + 'px' }">
                                    {{ readList.title }}
                                </h1>
                                <div class="book-inner"
                                    :style="{ fontSize: fontSize + 'px' , 'line-height': lineHeight + 'px', }"
                                    v-html="readList.info"></div>
                            </div>
                        </section>
                    </div>
                    <p class="article-page">{{ currentPaging + "/" + resultPaging }}</p>
                    <div class="read-ad">
                        <ad-js name="320x100"></ad-js>
                    </div>
                </div>
            </v-touch>
        </div>
        <template v-if="readList.info">
            <div v-if="user_infoList.is_vip != '1'">
                <div class="is_pay" @click="show_pay = true" v-if="readList.coin != 0 && readList.is_pay != 1">
                    <p class="is_pay_title">本章需要 <span>书豆</span> 阅读</p>
                    <img src="../../assets/images/read_menu_pay_tit.png" alt="" class="read_menu_pay_tit" />
                </div>
            </div>
        </template>

        <!-- v-html="bookChaptersBody(readList.info)" -->
        <!-- 下方菜单 -->
        <!-- <ReadingList
        @directoryClick="directoryClick"
        @preId="preId"
        @nextId="nextId"
        :background="background"
        :Color="Color"
        v-if="readList.info"
      ></ReadingList> -->


        <v-touch class="adpanel" v-if="isadpanel === 1" @swipeleft="adpanelswipeleft" @swiperight="adpanelswiperight"
            @tap="adpanelswipertap" :style="{ 
      background: background,
      'position':'fixed',
      width:'100%',
      height:'100%',
      top:'0px',
      left:'0px',
      'z-index':999,
      }">

            <div class="adpanel-Rewardedbtn">
                <p class="adpanel-Rewardedtitle" @click="Rewardedbtn" v-show="timeout >= 0">{{timeout}}秒广告</p>
                <div>
                    <ad-js name="video"></ad-js>
                </div>
                <div>
                    <ad-js name="300x250"></ad-js>
                </div>

            </div>

        </v-touch>

        <!--        侧边目录-->
        <van-popup v-model="show_left" position="left" style="height: 100%; width: 75%; background: #e7e7e7">
            <Left :id="id" :chapterList="chapterList" :end="end" :chaps="readList.chaps" @chapsClick="chapsClick"
                :background="background" :Color="Color" :user_infoList="user_infoList"
                :style="{ background: background }"></Left>
        </van-popup>
        <!--        底部设置-->
        <van-popup v-model="show" position="bottom" :overlay="false" class="button-reading"
            :style="{ background: background }">
            <div class="mask">
                <Button @leftClick="leftClick" @backgroundClick="backgroundClick" @fontSizeClick="fontSizeClick"
                    :value="valid" @preId="preId" @nextId="nextId" :background="background" :Color="Color"
                    @switchClick="switchClick" :checked="checked" :user_infoList="user_infoList"></Button>
            </div>
        </van-popup>
        <!--        阅读时间-->
        <van-popup v-model="show" position="right" :overlay="false" class="reading-right"
            :style="{ background: background }">
            <div class="right" :class="background == Color ? 'active' : ''">
                <img src="../../assets/images/bookbean_icon.png" alt="" class="bookbean_icon" />
                已阅读{{ base_infoList.today_read_time }}分钟
                <van-count-down :time="time" format="ss" ref="countDown" @change="timeClick" @finish="finishClick"
                    style="display: none" />
            </div>
        </van-popup>
        <!--        书豆余额-->
        <van-popup v-model="show" position="right" :overlay="false" class="reading-right dou-right"
            :style="{ background: background }">
            <div class="right" :class="background == Color ? 'active' : ''">
                <img src="../../assets/images/bookbean_icon.png" alt="" class="bookbean_icon" />
                {{ base_infoList.coin }}
            </div>
        </van-popup>
        <!--        付费章节购买-->
        <van-popup v-model="show_pay" position="bottom" closeable v-if="user_infoList.is_vip != '1'">
            <Pay :chapterList="chapterList" :readList="readList" @payList="payList" @topupClick="topupClick"
                @buttonClick="buttonClick"></Pay>
        </van-popup>

        <van-popup v-model="logo_show" position="bottom" :style="{ height: '20%' }">
            <van-button type="primary" size="large" round class="logo-button" @click="onCLickAvatar">登录</van-button>
        </van-popup>
        <!--        强制让用户关注公众号-->
        <van-overlay :show="Focus_show" @click="Focus_show = false">
            <div class="wrapper" @click.stop>
                <div class="share_arrows_div">
                    <img src="../../assets/images/share_arrows@2x.png" alt="" class="share_arrows" />
                </div>
                <div class="block">
                    <div>
                        <p class="wrapper_title">长按识别二维码关注公众号</p>
                        <p class="wrapper_subtitle">解锁更多章节</p>
                    </div>
                    <img :src="Focus_url" alt="" class="Focus_url_img" />
                    <p class="wrapper_subtitle">已关注，请点击右上角进行刷新</p>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import Button from "../../components/ReadingView/Button";
    import Left from "../../components/ReadingView/Left";
    import ReadingList from "../../components/ReadingView/ReadingList";
    import Pay from "../../components/ReadingView/Pay";
    import axios from "axios";

    export default {
        components: {
            Button,
            Left,
            ReadingList,
            Pay,
        },
        name: "Reading",
        data() {
            return {
                timeout: 0,
                Focus_show: false,
                Focus_url: "",
                is_subscribe: "",
                generalize: "",
                subchaps: "",
                Color: "#543131",
                show: false,
                show_left: false,
                show_pay: false,
                logo_show: false,
                background: "#E7E7E7",
                fontSize: Number,
                lineHeight: Number,
                valid: Number,
                id: this.$route.query.id,
                readList: [],
                chapterList: [],
                end: [],
                chaps: "",
                base_infoList: [],
                time: 60 * 1000,
                time_total: 0,
                times: 0,
                Time: 60,
                checked: Boolean,
                user_infoList: [],
                self_time: 0,
                userAgent: true,
                code: "",

                //当前章当前页
                currentPaging: 1,
                //当前章总页
                resultPaging: 1,
                offsetX: 0,
                margin: 0,
                styleObject: "",
                isshowpage: 1,
                clWidth: document.documentElement.clientWidth || document.body.clientWidth,

                isadpanel: 0,
                ad_interval: 5,
                currentpage: 0,
            };
        },
        created() {
            if (typeof(system) === 'undefined') {
                window.system = 0;
            }
            this.self_time = parseInt(new Date().getTime() / 1000);
            if (this.$route.query.generalize) {
                this.generalize = this.$route.query.generalize;
                this.subchaps = this.$route.query.subchaps;
            } else {
                // this.generalize = localStorage.getItem('generalize')
                // this.subchaps = localStorage.getItem('subchaps')
            }
            this.chaps = this.$route.query.chaps;
            if (this.generalize && localStorage.getItem("token")) {
                // 公众号二维码
                this.is_subscribe_wechat_mp();
                this.wechat_subscribe_qrcode();
            } else {
                this.readClick();
            }
            if (/MicroMessenger/.test(window.navigator.userAgent)) {
                if (this.generalize) {
                    //在微信中
                    // localStorage.setItem('generalize', this.generalize)
                    this.userAgent = true;
                    if (!localStorage.getItem("token")) {
                        this.code = this.GetUrlParam("code");
                        if (this.code) {
                            this.getOpenId(this.code);
                        } else {
                            this.WeiXin();
                        }
                    }
                }
            } else {
                //在浏览器中打开
                this.userAgent = false;
                localStorage.setItem("generalize", this.generalize);
            }

            this.user_info();
            this.locationClick();
            this.chapterPOST();
            this.base_info();
        },
        destroyed() {
            this.count_read_time2();
        },
        mounted() {
            if (system) {
                //window.localStorage.clear();

                system.onmessage = function(data) {
                    //101代表观看视频免30分钟广告
                    if (data === "101") {
                        let Exempttime = 0;
                        if (window.localStorage.getItem("ExemptAD_time") != null) {
                            Exempttime = window.localStorage.getItem("ExemptAD_time") * 1;
                        } else {
                            Exempttime = new Date().getTime() / 1000;
                        }
                        //存储剩余免广告时间 +1800秒即免30分钟
                        window.localStorage.setItem("ExemptAD_time", Exempttime + 1800 + "");
                    }
                };

                system.postMessage("showbanner");
                //this.$toast("展示banner广告");

            }
        },
        methods: {
            GetIsExemptAD() {
                if (window.localStorage.getItem("ExemptAD_time") == null) {
                    return false;
                }
                //string转number +"" number转string *1
                let ExemptAD_time = window.localStorage.getItem("ExemptAD_time") * 1;
                let now_time = new Date().getTime() / 1000;
                //判断免广告是否过时
                if (now_time > ExemptAD_time) {
                    window.localStorage.setItem(
                        "ExemptAD_time",
                        new Date().getTime() / 1000 + ""
                    );
                    return false;
                }

                return true;
            },
            //左滑 下一页
            touchswipeleft() {
                let clWidth =
                    document.documentElement.clientWidth || document.body.clientWidth;

                //翻页
                this.currentPaging++;
                //下一章
                if (this.currentPaging === this.resultPaging + 1) {
                    this.nextId();
                }
                //下一页
                else {
                    //没有开通VIP则记页数准备插入广告
                    if (this.user_infoList.is_vip != "1" && !this.GetIsExemptAD()) {
                        this.currentpage++;
                        if (this.currentpage % this.ad_interval === 0) {
                            this.isadpanel = 1;
                            this.timeout = 5;
                            var timer = setInterval(() => {
                                this.timeout--;
                                if (this.timeout < 0) {
                                    clearInterval(timer);
                                    // this.adpanelswiperight();
                                }
                            }, 1000);
                            // if (system) {
                            //     system.postMessage("initandshowInterAd");
                            //     //this.$toast("展示插屏广告");
                            // }
                        }
                    }

                    this.offsetX -= clWidth - this.margin;
                    this.styleObject = {
                        transform: "translateX(" + this.offsetX + "px" + ")",
                        transitionDuration: "0.5s",
                    };
                }
            },
            //右滑 上一页
            touchswiperight() {
                let clWidth =
                    document.documentElement.clientWidth || document.body.clientWidth;

                if (this.currentPaging === 1) {
                    //上一章

                    this.preId();
                } else {
                    //上一页
                    this.currentPaging--;
                    this.offsetX += clWidth - this.margin;
                    this.styleObject = {
                        transform: "translateX(" + this.offsetX + "px" + ")",
                        transitionDuration: "0.5s",
                    };
                }
            },
            touchswipertap() {
                this.show = !this.show;

                //如果显示菜单栏则关闭banner广告，反之打开banner，防止banner覆盖在菜单栏上
                if (system) {
                    if (this.show) {
                        system.postMessage("hidebanner");
                        //this.$toast("隐藏banner广告");
                    } else {
                        system.postMessage("showbanner");
                        //this.$toast("展示banner广告");
                    }
                }
            },

            adpanelswipeleft() {
                // system.postMessage("hideInterAd");
                if (this.timeout < 0) {
                    this.isadpanel = 0;
                }
            },
            adpanelswiperight() {
                // system.postMessage("hideInterAd");
                if (this.timeout < 0) {
                    this.isadpanel = 0;
                }
            },
            adpanelswipertap() {
                //this.show = !this.show;
            },
            Rewardedbtn() {
                // system.postMessage("initandshowRewardedVideoAd");
                //this.$toast("播放激励视频");
            },

            wechat_subscribe_qrcode() {
                this.$axios
                    .post("/api/user/wechat_subscribe_qrcode", {
                        scene: "anime_generalize",
                        scene_data: {
                            generalize: this.generalize
                        },
                    })
                    .then((res) => {
                        this.Focus_url = res.data.data.data.url;
                    });
            },
            is_subscribe_wechat_mp() {
                this.$toast.loading({
                    message: "加载中...",
                    duration: 0,
                    overlay: true,
                });
                this.$axios.post("/api/user/is_subscribe_wechat_mp").then((res) => {
                    this.$toast.clear();
                    this.is_subscribe = res.data.data.is_subscribe;
                    this.readClick();
                });
            },
            // 授权登录
            GetUrlParam(name) {
                //封装方法
                var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
                var r = window.location.search.substr(1).match(reg); //匹配目标参数
                if (r != null) return unescape(r[2]);
                return null; //返回参数值
            },
            // 登录
            WeiXin() {
                //注意事项：回调地址必须要在公众号里进行配置回调地址才会生效
                this.code = this.GetUrlParam("code"); // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
                var local = window.location.href;
                if (!this.code) {
                    window.location.href =
                        axios.defaults.baseURL +
                        "/api/wechat_official" +
                        "?redirect_uri=" +
                        encodeURIComponent(local) +
                        "&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
                } else {
                    this.getOpenId(this.code); //把code传给后台获取用户信息
                }
            },
            getOpenId(code) {
                // 通过code获取 openId等用户信息，/api/user/wechat/login 为后台接口
                this.$axios
                    .post("/api/wechat_official_account_login", {
                        code: code,
                    })
                    .then((res) => {
                        if (res.data.data.unregistered == "1") {
                            this.$toast("授权登录");
                            this.$router.push({
                                path: "/replacephone?openid=" + res.data.data.openid,
                            });
                        } else {
                            this.$toast.success("登录成功");
                            localStorage.setItem("token", res.data.data.access_token);
                            localStorage.setItem("openid", res.data.data.openid);
                            this.$nextTick(() => {
                                this.user_info();
                            });
                        }
                    });
            },
            // 授权登录 end
            user_info() {
                this.$axios.get("/api/user_info").then((res) => {
                    var res = res.data.data;
                    this.user_infoList = res;
                });
            },
            returnClick() {
                this.show = false;
                if (this.chapterList.is_shelve == 1) {
                    this.count_read_time(1);
                    this.add_read_history();
                    if (this.generalize) {
                        this.$router.push({
                            path: "/",
                        });
                    } else {
                        this.$router.go(-1);
                    }
                } else {
                    this.$dialog
                        .alert({
                            title: "加入书架",
                            message: "是否将本书加入书架",
                            showCancelButton: "true",
                            confirmButtonText: "加入书架",
                            confirmButtonColor: "#4D77FD",
                            cancelButtonColor: "#606266",
                            cancelButtonText: "暂不加入",
                        })
                        .then(() => {
                            this.$axios
                                .get("/api/bookstores/add_to_shelve?anime_id=" + this.id)
                                .then((res) => {
                                    if (res.data.code == 200) {
                                        this.$toast(res.data.message);
                                        this.count_read_time(1);
                                        this.add_read_history();
                                        if (this.generalize) {
                                            this.$router.push({
                                                path: "/",
                                            });
                                        } else {
                                            this.$router.go(-1);
                                        }
                                    }
                                })
                                .catch((err) => {
                                    this.$toast(err.response.data.message);
                                });
                        })
                        .catch(() => {
                            this.count_read_time(1);
                            this.add_read_history();
                            if (this.generalize) {
                                this.$router.push({
                                    path: "/",
                                });
                            } else {
                                this.$router.go(-1);
                            }
                        });
                }
            },
            topupClick() {
                if (!localStorage.getItem("token")) {
                    this.$toast("请登录");
                    return;
                }
                this.show = false;
                this.show_pay = false;
                this.count_read_time(1);
                this.add_read_history();
                this.$nextTick(() => {
                    this.$router.push({
                        path: "/topup",
                    });
                });
            },
            buttonClick() {
                if (!localStorage.getItem("token")) {
                    this.$toast("请登录");
                    return;
                }
                this.show = false;
                this.show_pay = false;
                this.count_read_time(1);
                this.add_read_history();
                this.$nextTick(() => {
                    this.$router.push({
                        path: "/vip",
                    });
                });
            },
            // 开启自动付费章节
            switchClick(checkbox) {
                if (this.user_infoList.is_vip == "1") {
                    this.$toast("已开通畅读vip");
                    return;
                }
                if (checkbox) {
                    this.$toast("开启自动购买章节");
                    localStorage.setItem("checked", "1");
                } else {
                    this.$toast("关闭自动购买章节");
                    localStorage.setItem("checked", "0");
                }
                this.checked = checkbox;
                if (this.readList.is_pay == "0" && this.readList.coin != "0") {
                    this.payList();
                }
            },
            timeClick(e) {
                if (!localStorage.getItem("token")) {
                    this.$refs.countDown.pause();
                    return;
                }
                this.times = e.seconds;
            },
            finishClick() {
                this.time_total += 60;
                if (this.time_total == 120) {
                    return;
                }
                this.count_read_time();
                this.$refs.countDown.reset();
            },
            // 购买章节
            payList() {
                this.$axios
                    .post("/api/bookstores/pay_chapter", {
                        anime_id: this.readList.anid,
                        chaps: this.readList.chaps,
                    })
                    .then((res) => {
                        if (!this.checked) {
                            this.$toast("成功购买章节！");
                        }
                        this.chapterPOST();
                        this.readClick();
                    })
                    .catch((err) => {
                        this.$toast(err.response.data.message);
                        this.show_pay = true;
                        this.readList.info = this.readList.info.substr(0, 300);
                    });
            },

            // 阅读时间
            count_read_time2(e) {
                this.Time = this.Time - this.times;
                var time_send = parseInt(new Date().getTime() / 1000) - this.self_time;
                this.$axios
                    .get("/api/bookstores/count_read_time", {
                        params: {
                            time: time_send,
                        },
                    })
                    .then((res) => {
                        this.Time = 60;
                        this.time_total = 0;
                        if (!e) {
                            this.$refs.countDown.reset();
                        }
                        this.base_info();
                    });
            },

            // 阅读时间
            count_read_time(e) {
                return;
                this.Time = this.Time - this.times;
                this.$axios
                    .get("/api/bookstores/count_read_time4", {
                        params: {
                            time: this.Time,
                        },
                    })
                    .then((res) => {
                        this.Time = 60;
                        this.time_total = 0;
                        if (!e) {
                            this.$refs.countDown.reset();
                        }
                        this.base_info();
                    });
            },
            base_info() {
                this.$axios
                    .get("/api/welfare/base_info")
                    .then((res) => {
                        this.base_infoList = res.data.data;
                    })
                    .catch((err) => {
                        this.$toast(err.response.data.message);
                    });
            },
            nextId() {
                this.show = false;
                this.chaps = this.readList.nextId;
                if (!this.chaps) {
                    this.$toast("已经是最后一页了");
                    return;
                }
                this.isadpanel = 0;

                this.readClick_dir(2);
                this.count_read_time();
            },
            preId() {
                this.show = false;
                this.chaps = this.readList.preId;
                if (!this.chaps) {
                    this.$toast("已经是第一页了");
                    return;
                }
                this.isadpanel = 0;

                this.readClick_dir(1);
                this.count_read_time();
            },
            chapsClick(item) {
                this.chaps = item.chaps;
                this.readClick();
                this.show_left = false;
            },
            chapterPOST() {
                this.$axios
                    .post("/api/bookstores/chapter", {
                        anime_id: this.id,
                    })
                    .then((res) => {
                        if (this.readList.coin != 0 && this.readList.is_pay == 0) {
                            if (!localStorage.getItem("token")) {
                                this.logo_show = true;
                            } else {
                                this.show_pay = true;
                            }
                        } else {
                            this.show_pay = false;
                        }

                        var res = res.data.data;
                        this.chapterList = res;
                        this.end =
                            this.chapterList.chapters[this.chapterList.chapters.length - 1];
                    })
                    .catch((err) => {
                        this.$toast(err.response.data.message);
                    });
            },
            readClick() {
                if (this.id == sessionStorage.getItem("anime_id")) {
                    this.chaps = sessionStorage.getItem("chaps");
                    sessionStorage.removeItem("chaps");
                    sessionStorage.removeItem("anime_id");
                }
                this.$axios
                    .post("/api/bookstores/read", {
                        anime_id: this.id,
                        chaps: this.chaps,
                    })
                    .then((res) => {
                        this.$refs.scroll.scrollTop = 0;
                        var res = res.data.data;
                        res.info = this.html_encode(res.info);
                        this.readList = res;
                        if (this.user_infoList.is_vip != "1") {
                            if (this.readList.coin != 0 && this.readList.is_pay != "1") {
                                if (this.checked) {
                                    this.payList();
                                    return;
                                }
                                if (!localStorage.getItem("token")) {
                                    this.logo_show = true;
                                    sessionStorage.setItem("chaps", this.chaps);
                                    sessionStorage.setItem("anime_id", this.id);
                                    return;
                                } else {
                                    this.show_pay = true;
                                }
                                this.readList.info = this.readList.info.substr(0, 300);
                            }
                        }
                        if (this.generalize && localStorage.getItem("token")) {
                            if (this.readList.chaps >= this.subchaps) {
                                if (this.is_subscribe == "0") {
                                    this.Focus_show = true;
                                }
                            }
                        }
                    });
            },
            readClick_dir(dir) {
                if (this.id == sessionStorage.getItem("anime_id")) {
                    this.chaps = sessionStorage.getItem("chaps");
                    sessionStorage.removeItem("chaps");
                    sessionStorage.removeItem("anime_id");
                }
                this.$axios
                    .post("/api/bookstores/read", {
                        anime_id: this.id,
                        chaps: this.chaps,
                    })
                    .then((res) => {
                        this.$refs.scroll.scrollTop = 0;
                        var res = res.data.data;
                        res.info = this.html_encode(res.info);
                        this.readList = res;
                        if (this.user_infoList.is_vip != "1") {
                            if (this.readList.coin != 0 && this.readList.is_pay != "1") {
                                if (this.checked) {
                                    this.payList();
                                    return;
                                }
                                if (!localStorage.getItem("token")) {
                                    this.logo_show = true;
                                    sessionStorage.setItem("chaps", this.chaps);
                                    sessionStorage.setItem("anime_id", this.id);
                                    return;
                                } else {
                                    this.show_pay = true;
                                }
                                this.readList.info = this.readList.info.substr(0, 300);
                            }
                        }
                        if (this.generalize && localStorage.getItem("token")) {
                            if (this.readList.chaps >= this.subchaps) {
                                if (this.is_subscribe == "0") {
                                    this.Focus_show = true;
                                }
                            }
                        }
                        this.$nextTick(function() {
                            //1上一章
                            if (dir === 1) {
                                let scrollW = this.$refs.bookInner.scrollWidth;
                                let clientW = this.$refs.bookInner.clientWidth;
                                this.resultPaging = Math.floor(scrollW / clientW);

                                this.currentPaging = this.resultPaging;
                                let clWidth =
                                    document.documentElement.clientWidth ||
                                    document.body.clientWidth;

                                this.offsetX = -(
                                    (clWidth - this.margin) *
                                    (this.currentPaging - 1)
                                );
                                this.styleObject = {
                                    transform: "translateX(" + this.offsetX + "px" + ")",
                                };
                            }
                            //2下一章
                            else if (dir === 2) {
                                this.styleObject = "";
                                this.offsetX = 0;
                                this.currentPaging = 1;
                            }
                        });
                    });
            },
            html_encode(str) {
                var s = "";
                if (str.length == 0) return "";
                s = str.replace(/&/g, "&amp;");
                s = s.replace(/</g, "&lt;");
                s = s.replace(/>/g, "&gt;");
                s = s.replace(/ /g, "&nbsp;");
                s = s.replace(/\'/g, "&#39;");
                s = s.replace(/\"/g, "&quot;");
                s = s.replace(/\n/g, "<br/>");
                return s;
            },
            directoryClick() {
                this.show_left = true;
            },
            locationClick() {
                if (localStorage.getItem("checked") == "1") {
                    this.checked = true;
                } else {
                    this.checked = false;
                }
                if (localStorage.getItem("background")) {
                    this.background = localStorage.getItem("background");
                } else {
                    this.background = "#E7E7E7";
                }
                if (localStorage.getItem("fontSize")) {
                    this.fontSize = Number(localStorage.getItem("fontSize"));
                    this.valid = this.fontSize;
                    this.lineHeight = this.fontSize + 20;
                } else {
                    this.fontSize = 18;
                    this.valid = this.fontSize;
                    this.lineHeight = this.fontSize + 20;
                }
            },
            add_read_history() {
                this.$axios
                    .post("/api/bookstores/add_read_history", {
                        anime_id: this.id,
                        chaps: this.readList.chaps,
                    })
                    .then((res) => {});
            },
            readingClick() {
                this.show = !this.show;
            },
            leftClick() {
                this.show = false;
                this.show_left = true;
            },
            backgroundClick(item) {
                this.background = item;
            },
            fontSizeClick(value) {
                this.fontSize = value;
                this.valid = this.fontSize;
                this.lineHeight = this.fontSize + 20;

                this.$nextTick(function() {
                    let scrollW = this.$refs.bookInner.scrollWidth;
                    let clientW = this.$refs.bookInner.clientWidth;
                    this.resultPaging = Math.floor(scrollW / clientW);

                    if (this.currentPaging > this.resultPaging) {
                        this.currentPaging = this.resultPaging;

                        let clWidth =
                            document.documentElement.clientWidth || document.body.clientWidth;

                        this.offsetX = -((clWidth - this.margin) * (this.currentPaging - 1));
                        this.styleObject = {
                            transform: "translateX(" + this.offsetX + "px" + ")",
                        };
                    }
                    console.log("fontSizeClick----------------");
                });
            },
            onCLickAvatar() {
                if (this.user_infoList) {
                    this.upClick();
                } else {
                    this.login();
                }
            },
            upClick() {
                if (!localStorage.getItem("token")) {
                    if (this.userAgent) {
                        this.WeiXin();
                    } else {
                        this.$router.push({
                            path: "/login",
                        });
                    }
                    return;
                }
                this.$router.push({
                    path: "/up",
                });
            },
            login() {
                if (this.userAgent) {
                    this.WeiXin();
                } else {
                    this.$router.push({
                        path: "/login",
                    });
                }
            },
        },
        watch: {
            readList(newVal, oldVla) {
                this.$nextTick(function() {
                    if (this.$refs.bookInner) {
                        let scrollW = this.$refs.bookInner.scrollWidth;
                        let clientW = this.$refs.bookInner.clientWidth;
                        this.resultPaging = Math.floor(scrollW / clientW);

                        if (this.currentPaging > this.resultPaging) {
                            this.currentPaging = this.resultPaging;

                            let clWidth =
                                document.documentElement.clientWidth || document.body.clientWidth;

                            this.offsetX = -(
                                (clWidth - this.margin) *
                                (this.currentPaging - 1)
                            );
                            this.styleObject = {
                                transform: "translateX(" + this.offsetX + "px" + ")",
                            };
                        }
                    }
                });
            },
        },
    };
</script>

<style scoped>
    .share_arrows_div {
        position: fixed;
        top: 40px;
        right: 60px;
        color: #fff;
        text-align: right;
    }

    .share_arrows {
        width: 107px;
        height: 170px;
        margin-bottom: 30px;
    }

    .wrapper_title {
        text-align: center;
        font-size: 36px;
        padding: 30px 0 0 0;
    }

    .wrapper_subtitle {
        font-size: 30px;
        text-align: center;
        padding: 30px 0 0 0;
    }

    .Focus_url_img {
        width: 550px;
        height: 550px;
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }

    .block {
        background-color: #fff;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .is_pay_title>span {
        color: #fcad0f;
    }

    .is_pay {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 100px 0;
    }

    .is_pay_title {
        font-size: 32px;
        color: #606266;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
    }

    .read_menu_pay_tit {
        height: 17px;
    }

    .active {
        color: #fff !important;
    }

    .reading-right {
        border-radius: 30px 0 0 30px;
        top: 300px;
        box-shadow: 0px 0px 18px 0px rgba(132, 134, 156, 0.2);
    }

    .dou-right {
        top: 200px;
    }

    .right {
        font-size: 24px;
        padding: 20px 40px 20px 30px;
        color: #303133;
        display: flex;
        align-items: center;
    }

    .bookbean_icon {
        width: 19px;
        height: 20px;
        margin-right: 10px;
        display: inline-block;
    }

    .button-reading {
        box-shadow: 0px 0px 18px 0px rgba(132, 134, 156, 0.1);
    }

    .mask {
        padding: 50px 40px;
    }

    .reading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        text-align: justify;
        font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
            Segoe UI, Arial, Roboto, "PingFang SC", "Hiragino Sans GB",
            "Microsoft Yahei", sans-serif;
    }

    .read-content {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .read-ad {
        position: absolute;
        right: 0px;
        left: 0px;
        bottom: 0px;
    }

    .read-article {
        position: absolute;
        overflow: hidden;
        text-align: justify;

        right: 0px;
        left: 0px;
        bottom: 200px;
        top: 50px;
        /* padding-left: 20px;
  padding-right: 20px; */
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .read-section {
        height: 100%;
    }

    .read-section-inner {
        overflow: visible;
        height: 100%;
    }

    .book-inner {
        /* line-height: 60px;  no */
        /* text-indent: 2em;  */
        padding-left: 30px;
        padding-right: 30px;
    }

    .book-title {
        line-height: 50px;
        font-weight: 400;
        margin-bottom: 25px;
        padding-left: 10px;
    }

    .book-maintitle {
        line-height: 50px;
        font-weight: 400;
        margin-bottom: 25px;
        text-align: center;
    }

    .article-page {
        color: #585858;
        font-size: 13px;
        position: absolute;
        left: 10px;
        bottom: 180px;
    }

    .adpanel-Rewardedtitle {
        text-align: center;
        margin-top: 30rem;
        color: #2323ff;
        font-weight: bold;
    }

    .logo-button {
        margin-top: 80px;
    }
</style>